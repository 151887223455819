import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className="header-container">
      <Link to="/dagens/" className="title-link">
        <h1 className="main-title">ORDFRAS</h1>
      </Link>
      <Link to="/arkiv/" className="archive-link">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 8v13H3V8M1 3h22v5H1V3zM10 12h4" />
        </svg>
      </Link>
    </div>
  );
};

export default Header; 