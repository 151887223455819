import React from 'react';

const WordList = ({ wordDefinitions }) => {
  return (
    <div className="word-list-container">
      <h2 className="word-list-title">Ordlista</h2>
      
      {wordDefinitions.map((wordDef, index) => (
        <div className="word-item" key={index}>
          <div className="word">{wordDef.word}</div>
          <div className="definition" dangerouslySetInnerHTML={{ __html: wordDef.definition }}></div>
          <div className="example" dangerouslySetInnerHTML={{ __html: `"${wordDef.example}"`}}></div>
          <div className="etymology" dangerouslySetInnerHTML={{ __html: wordDef.etymology }}></div>
        </div>
      ))}
    </div>
  );
};

export default WordList; 