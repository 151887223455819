import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import OrdfrasGame from "./OrdfrasGame";
import { isValidDateFormat } from "./utils/dateUtils";
import "./styles.css";
import Archive from './components/Archive';
import Header from './components/Header';

// Game Container Component with Header
const GameContainer = ({ children }) => (
  <div className="App">
    <Header />
    {children}
  </div>
);

// Different Game Routes
const DailyGame = () => (
  <GameContainer>
    <OrdfrasGame />
  </GameContainer>
);

const DateSpecificGame = ({ date }) => {
  // Validate date format
  if (!isValidDateFormat(date)) {
    return <Navigate to="/dagens/" replace />;
  }
  
  return (
    <GameContainer>
      <OrdfrasGame date={date} />
    </GameContainer>
  );
};

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Redirect from home to dagens */}
          <Route path="/" element={<Navigate to="/dagens/" replace />} />
          
          {/* Daily game route */}
          <Route path="/dagens/" element={<DailyGame />} />
          
          {/* Date-specific game route */}
          <Route 
            path="/:date" 
            element={
              <DateRouter />
            } 
          />
          
          {/* Archive route */}
          <Route path="/arkiv/" element={<Archive />} />
          
          {/* Fallback for invalid routes */}
          <Route path="*" element={<Navigate to="/dagens/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

// Special component to handle date parameter
const DateRouter = () => {
  // Get the date parameter from URL
  const pathname = window.location.pathname;
  const date = pathname.substring(1).replace(/\/$/, ''); // Remove starting / and trailing / if present
  
  return <DateSpecificGame date={date} />;
};

export default App;
