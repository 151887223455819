/**
 * Date utilities for the Ordfras Game
 * Helps load the correct game data based on the current date
 */

/**
 * Returns the current date in YYYY-MM-DD format
 * @returns {string} Today's date in YYYY-MM-DD format
 */
export const getTodayFormatted = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

/**
 * Validates if a string is in YYYY-MM-DD format
 * @param {string} dateString - The date string to validate
 * @returns {boolean} True if the date is valid, false otherwise
 */
export const isValidDateFormat = (dateString) => {
  // Basic format check: YYYY-MM-DD
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) return false;
  
  // Check if it's an actual valid date
  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JS months are 0-based
  const day = parseInt(parts[2], 10);
  
  const date = new Date(year, month, day);
  
  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
};

/**
 * Returns the formatted display date for a specific date string
 * @param {string} dateString - The date in YYYY-MM-DD format
 * @returns {string} Formatted date string like "19 mars 2025"
 */
export const getFormattedDisplayDate = (dateString) => {
  // Use current date if no date string provided
  const date = dateString && isValidDateFormat(dateString) 
    ? new Date(dateString) 
    : new Date();
  
  const day = date.getDate();
  
  // Swedish month names
  const months = [
    'januari', 'februari', 'mars', 'april', 'maj', 'juni', 
    'juli', 'augusti', 'september', 'oktober', 'november', 'december'
  ];
  
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  return `${day} ${month} ${year}`;
};

/**
 * Dynamically imports a game data file based on the date
 * Falls back to default if a specific date file doesn't exist
 * @param {string} dateString - Optional date in YYYY-MM-DD format
 * @returns {Promise} A promise that resolves to the game data module
 */
export const loadGameData = async (dateString) => {
  // Use provided date if valid, otherwise use today's date
  const dateToUse = dateString && isValidDateFormat(dateString) 
    ? dateString 
    : getTodayFormatted();
    
  let gameData;
  
  try {
    // Try to import the file for the specified date
    gameData = await import(`../gameData/${dateToUse}.js`);
    console.log(`Loaded game for ${dateToUse}`);
    return { ...gameData, date: dateToUse };
  } catch (error) {
    console.log(`No game found for ${dateToUse}, loading default game`);
    try {
      // Fall back to the default game if the specified date doesn't exist
      gameData = await import('../gameData/YYYY-MM-DD.js');
      return { ...gameData, date: 'default' };
    } catch (fallbackError) {
      // If even the default game can't be loaded, throw an error
      console.error("Failed to load default game:", fallbackError);
      throw new Error("Could not load any game data, please check your game files.");
    }
  }
}; 