import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

// Add these constants at the top
const STORAGE_PREFIX = "ordfras_v2_";
const GAME_HISTORY_KEY = `${STORAGE_PREFIX}history`;

const getWinMessage = (movesLeft) => {
  if (movesLeft >= 5) {
    return "<b>OJ OJ OJ, RIKTIGT BRA</b><br>Var det här alldeles för lätt för dig??";
  } else if (movesLeft === 4) {
    return "<b>JISSES VA SNABBT</b><br>Jösses Amalia vad imponerande!";
  } else if (movesLeft === 3) {
    return "<b>FANTASTISKT JOBBAT</b><br>Du är en mästare på det här!";
  } else if (movesLeft === 2) {
    return "<b>WOW, PRECIS PÅ MÅLLINJEN</b><br>Snyggt löst med marginal!";
  } else if (movesLeft === 1) {
    return "<b>SNYGGT JOBBAT</b><br>Det satt långt inne, men du fixade det!";
  } else {
    return "<b>PUH, PÅ HÅRET</b><br>Men du klarade det ändå!";
  }
};

// Function to generate star emojis based on moves left
const getStars = (movesLeft) => {
  return movesLeft > 0 ? "⭐".repeat(movesLeft) : "🙊";
};

// Function to generate the reset counter emojis
const getResetSquares = (count, won) => {
  // Determine how many squares to show
  const totalSquares = Math.max(5, count + (won && count >= 5 ? 1 : 0));
  
  // Create squares array
  const squares = Array(totalSquares).fill().map((_, index) => {
    if (won && index === count && count >= 5) {
      // Add green square at the end if won after 5+ resets
      return "🟩";
    } else if (index < count) {
      // Red squares for each reset
      return "🟥";
    } else {
      // Remaining squares are green
      return "🟩";
    }
  });
  
  return squares.join('');
};

// Function to calculate time until midnight
const calculateTimeUntilMidnight = () => {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  
  const diffMs = tomorrow - now;
  
  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);
  
  return { hours, minutes, seconds };
};

const formatCompletionTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  } else if (minutes > 0) {
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  } else {
    return `00:${String(remainingSeconds).padStart(2, '0')}`;
  }
};

const getCompletionTime = (gameDate) => {
  const history = JSON.parse(localStorage.getItem(GAME_HISTORY_KEY)) || [];
  const result = history.find(item => item.date === gameDate);
  return result?.timeToComplete || 0;
};

const GameOverMessage = ({ won, movesLeft, gameDate, resetCount }) => {
  const [countdown, setCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [shareButtonText, setShareButtonText] = useState("Dela");
  
  // Update countdown every second (only when won)
  useEffect(() => {
    if (!won) return;
    
    const timer = setInterval(() => {
      setCountdown(calculateTimeUntilMidnight());
    }, 1000);
    
    // Initial countdown
    setCountdown(calculateTimeUntilMidnight());
    
    return () => clearInterval(timer);
  }, [won]);
  
  const handleShare = () => {
    const stars = getStars(movesLeft);
    const resetSquares = getResetSquares(resetCount, won);
    const shareText = `Ordfras ${gameDate || new Date().toISOString().split('T')[0]}
Försök: ${resetSquares}
Drag kvar: ${stars}

https://ordfras.se`;
    
    navigator.clipboard.writeText(shareText)
      .then(() => {
        setShareButtonText("Kopierad");
        setTimeout(() => setShareButtonText("Dela"), 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };
  
  const { hours, minutes, seconds } = countdown;
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return (
    <motion.div
      className={`game-over-message ${won ? "win" : "lose"}`}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      {won ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: getWinMessage(movesLeft) }}></div>
          <div className="completion-info">
            <span className="completion-time">{formatCompletionTime(getCompletionTime(gameDate))}</span>
            <span className="star-rating">{getStars(movesLeft)}</span>
          </div>
          
          <div className="next-game-container">
            <div className="countdown-section">
              <p className="countdown-title">Nästa spel om:</p>
              <p className="countdown-timer">{formattedTime}</p>
            </div>
            <button className="archive-button" onClick={handleShare}>
              {shareButtonText.toUpperCase()}
            </button>
          </div>

          <div className="archive-prompt">
            <p>Vill du spela mer? Spana in <Link to="/arkiv/">vårt arkiv</Link></p>
            <Link to="/arkiv/" className="archive-button">
              FLER SPEL I ARKIVET
            </Link>
          </div>
        </>
      ) : (
        "Attans osis! Klicka 'Börja om' för att testa igen"
      )}
    </motion.div>
  );
};

export default GameOverMessage;
