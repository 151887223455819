import React, { useCallback, useRef, useEffect } from "react";
import Tile from "./Tile";

const GameBoard = ({ grid, setGrid, correctGrid, moves, setMoves, gameOver, won, onFirstMove }) => {
  const gridRef = useRef(null);
  
  // Force disable all transitions on mount and updates
  useEffect(() => {
    if (gridRef.current) {
      const tiles = gridRef.current.querySelectorAll('.tile');
      tiles.forEach(tile => {
        tile.style.transition = 'none';
        tile.style.WebkitTransition = 'none';
      });
    }
  }, [grid]);
  
  const checkTileValidity = () => {
    let tileStatus = grid.map(row => row.map(() => "incorrect"));
    
    // Initialize row and column letter instances from correctGrid
    let rowLetterInstances = Array(5).fill().map(() => ({}));
    let columnLetterInstances = Array(5).fill().map(() => ({}));
    
    // Count letter instances in each row and column of correctGrid
    for (let rowIndex = 0; rowIndex < correctGrid.length; rowIndex++) {
      for (let colIndex = 0; colIndex < correctGrid[rowIndex].length; colIndex++) {
        const letter = correctGrid[rowIndex][colIndex];
        if (!letter) continue;
        
        // Count for rows
        rowLetterInstances[rowIndex][letter] = (rowLetterInstances[rowIndex][letter] || 0) + 1;
        
        // Count for columns
        columnLetterInstances[colIndex][letter] = (columnLetterInstances[colIndex][letter] || 0) + 1;
      }
    }
    
    // Mark correct positions (Green) and decrement row/column letter instances
    for (let rowIndex = 0; rowIndex < grid.length; rowIndex++) {
      for (let colIndex = 0; colIndex < grid[rowIndex].length; colIndex++) {
        const letter = grid[rowIndex][colIndex];
        if (!letter) continue;
        
        if (grid[rowIndex][colIndex] === correctGrid[rowIndex][colIndex]) {
          tileStatus[rowIndex][colIndex] = "correct";
          
          // Decrement row and column letter instances for this letter
          rowLetterInstances[rowIndex][letter]--;
          columnLetterInstances[colIndex][letter]--;
        }
      }
    }
    
    // Mark misplaced positions (Yellow) - Column Check
    for (let colIndex = 0; colIndex < grid[0].length; colIndex++) {
      // Skip columns that should contain nulls (2nd and 4th columns, indices 1 and 3)
      if (colIndex === 1 || colIndex === 3) continue;
      
      for (let rowIndex = 0; rowIndex < grid.length; rowIndex++) {
        const letter = grid[rowIndex][colIndex];
        if (!letter || tileStatus[rowIndex][colIndex] === "correct") continue;
        
        // Check if there are still instances of this letter needed in this column
        if (columnLetterInstances[colIndex][letter] > 0) {
          tileStatus[rowIndex][colIndex] = "misplaced";
          columnLetterInstances[colIndex][letter]--;
          
          // Also decrement row instances if applicable
          if (rowIndex !== 1 && rowIndex !== 3 && rowLetterInstances[rowIndex][letter] > 0) {
            rowLetterInstances[rowIndex][letter]--;
          }
        }
      }
    }
    
    // Mark misplaced positions (Yellow) - Row Check
    for (let rowIndex = 0; rowIndex < grid.length; rowIndex++) {
      // Skip rows that should contain nulls (2nd and 4th rows, indices 1 and 3)
      if (rowIndex === 1 || rowIndex === 3) continue;
      
      for (let colIndex = 0; colIndex < grid[rowIndex].length; colIndex++) {
        const letter = grid[rowIndex][colIndex];
        if (!letter || tileStatus[rowIndex][colIndex] === "correct" || tileStatus[rowIndex][colIndex] === "misplaced") continue;
        
        // Check if there are still instances of this letter needed in this row
        if (rowLetterInstances[rowIndex][letter] > 0) {
          tileStatus[rowIndex][colIndex] = "misplaced";
          rowLetterInstances[rowIndex][letter]--;
        }
      }
    }
  
    return tileStatus;
  };

  const tileValidity = checkTileValidity();

  // Optimized swap function with direct DOM text swap for instant visual update
  const swapLetters = useCallback((row1, col1, row2, col2) => {
    // Only swap if valid conditions are met
    if (!gameOver && moves > 0 && 
        tileValidity[row1][col1] !== "correct" && 
        tileValidity[row2][col2] !== "correct" && 
        grid[row1][col1] !== null && grid[row2][col2] !== null &&
        (row1 !== row2 || col1 !== col2)) {
      
      // Force disable all transitions on all tiles
      if (gridRef.current) {
        const tiles = gridRef.current.querySelectorAll('.tile');
        tiles.forEach(tile => {
          tile.style.transition = 'none';
          tile.style.WebkitTransition = 'none';
        });
      }
      
      // Create a new grid with a deep copy
      const newGrid = [...grid.map(row => [...row])];
      
      // Swap the letters in our data structure
      [newGrid[row1][col1], newGrid[row2][col2]] = [newGrid[row2][col2], newGrid[row1][col1]];
      
      // Update state (this will cause a re-render)
      setGrid(newGrid);
      setMoves(moves - 1);
      
      // Call onFirstMove after the first successful swap
      onFirstMove();
    }
  }, [grid, setGrid, moves, setMoves, gameOver, tileValidity, onFirstMove]);

  console.log("Game Over:", gameOver, "Won:", won);
  console.log("Grid Classes:", gameOver && !won ? "lose-grid" : "", won ? "win-grid" : "");

  return (
    <div 
      ref={gridRef}
      className={`grid ${gameOver && !won ? "lose-grid" : ""} ${won ? "win-grid" : ""}`}
      style={{ transition: "none" }}
    >
      {grid.map((row, rowIndex) =>
        row.map((letter, colIndex) => (
          <Tile
            key={`${rowIndex}-${colIndex}`}
            letter={letter}
            rowIndex={rowIndex}
            colIndex={colIndex}
            tileStatus={tileValidity[rowIndex][colIndex]}
            swapLetters={swapLetters}
            gameOver={gameOver}
            won={won}
          />
        ))
      )}
    </div>
  );
};

export default GameBoard;
