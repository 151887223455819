// OrdfrasGame.js - Main Component
import React, { useState, useEffect, useRef } from "react";
import GameBoard from "./components/GameBoard";
import GameOverMessage from "./components/GameOverMessage";
import Fireworks from "./components/Fireworks";
import WordList from "./components/WordList";
import { loadGameData, getFormattedDisplayDate } from "./utils/dateUtils";
import "./styles.css";

// Constants for localStorage keys
const STORAGE_PREFIX = "ordfras_v2_";
const GAME_STATE_KEY = (date) => `${STORAGE_PREFIX}gamestate_${date}`;
const GAME_HISTORY_KEY = `${STORAGE_PREFIX}history`;

// Check if localStorage is available
const isLocalStorageAvailable = () => {
  try {
    const testKey = `${STORAGE_PREFIX}test`;
    localStorage.setItem(testKey, "test");
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

// Helper functions for localStorage
const saveToLocalStorage = (key, data) => {
  if (!isLocalStorageAvailable()) return false;
  
  try {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  } catch (error) {
    console.error("Failed to save to localStorage:", error);
    return false;
  }
};

const loadFromLocalStorage = (key) => {
  if (!isLocalStorageAvailable()) return null;
  
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error("Failed to load from localStorage:", error);
    return null;
  }
};

// Reset Counter component to display green/red squares
const ResetCounter = ({ count, won }) => {
  // Determine how many squares to show
  const totalSquares = Math.max(5, count + (won && count >= 5 ? 1 : 0));
  
  // Create squares array
  const squares = Array(totalSquares).fill().map((_, index) => {
    if (won && index === count && count >= 5) {
      // Add green square at the end if won after 5+ resets
      return "🟩";
    } else if (index < count) {
      // Red squares for each reset
      return "🟥";
    } else {
      // Remaining squares are green
      return "🟩";
    }
  });
  
  return (
    <div className="reset-counter">
      {squares.join('')}
    </div>
  );
};

const OrdfrasGame = ({ date }) => {
  // State for game data
  const [gameData, setGameData] = useState({
    initialGrid: [],
    correctGrid: [],
    wordDefinitions: [],
    name: ""
  });
  const [grid, setGrid] = useState([]);
  const [moves, setMoves] = useState(15);
  const [gameOver, setGameOver] = useState(false);
  const [won, setWon] = useState(false);
  const [showWordList, setShowWordList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayDate, setDisplayDate] = useState("");
  const [gameDate, setGameDate] = useState("");
  const [resetCount, setResetCount] = useState(0);
  const [firstMoveTime, setFirstMoveTime] = useState(null);
  
  // Create a ref for the game completion container
  const gameCompletionRef = useRef(null);

  // Load game data when component mounts or date changes
  useEffect(() => {
    const fetchGameData = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await loadGameData(date);
        setGameData({
          initialGrid: data.initialGrid,
          correctGrid: data.correctGrid,
          wordDefinitions: data.wordDefinitions || [],
          name: data.name
        });
        
        // Set the actual game date
        const actualGameDate = data.date || date;
        setGameDate(actualGameDate);
        setDisplayDate(getFormattedDisplayDate(actualGameDate));
        
        // Try to load saved game state from localStorage
        const savedState = loadFromLocalStorage(GAME_STATE_KEY(actualGameDate));
        
        if (savedState) {
          // Restore saved state
          setGrid(savedState.grid);
          setMoves(savedState.moves);
          setResetCount(savedState.resetCount);
          setGameOver(savedState.gameOver);
          setWon(savedState.won);
          setShowWordList(savedState.showWordList);
          setFirstMoveTime(savedState.firstMoveTime);
        } else {
          // Initialize new game state
          setGrid(JSON.parse(JSON.stringify(data.initialGrid))); // Deep copy
          setMoves(15);
          setResetCount(0);
          setGameOver(false);
          setWon(false);
          setShowWordList(false);
          setFirstMoveTime(null);
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Failed to load game data:", error);
        setError("Kunde inte ladda spelet. Försök igen senare.");
        setLoading(false);
      }
    };

    fetchGameData();
  }, [date]);

  // Save game state to localStorage when relevant state changes
  useEffect(() => {
    if (!loading && !error && grid.length > 0 && gameDate) {
      // Only save if we have valid game data
      const gameState = {
        grid,
        moves,
        resetCount,
        gameOver,
        won,
        showWordList,
        firstMoveTime,
        timestamp: new Date().toISOString()
      };
      
      saveToLocalStorage(GAME_STATE_KEY(gameDate), gameState);
    }
  }, [grid, moves, resetCount, gameOver, won, showWordList, gameDate, loading, error, firstMoveTime]);

  // Check for win/loss conditions
  useEffect(() => {
    if (!loading && !error && grid.length > 0 && gameData.correctGrid.length > 0) {
      if (grid.flat().every((letter, index) => letter === gameData.correctGrid.flat()[index])) {
        setWon(true);
        setGameOver(true);
        setShowWordList(true);
        
        const completionTime = new Date().toISOString();
        const timeToComplete = firstMoveTime ? 
          Math.floor((new Date(completionTime) - new Date(firstMoveTime)) / 1000) : 0;

        // Save game completion to history
        const history = loadFromLocalStorage(GAME_HISTORY_KEY) || [];
        const gameResult = {
          date: gameDate,
          won: true,
          movesRemaining: moves,
          resetCount,
          stars: Math.min(5, Math.max(1, Math.ceil(moves / 3))),
          timestamp: new Date().toISOString(),
          firstMoveTime,
          completionTime,
          timeToComplete
        };
        
        // Update history if this date doesn't exist yet, or if it's a better result
        const existingIndex = history.findIndex(item => item.date === gameDate);
        if (existingIndex >= 0) {
          // If existing result is a loss or has fewer moves remaining, update it
          if (!history[existingIndex].won || history[existingIndex].movesRemaining < moves) {
            history[existingIndex] = gameResult;
          }
        } else {
          // Add new result
          history.push(gameResult);
        }
        
        saveToLocalStorage(GAME_HISTORY_KEY, history);
      } else if (moves === 0) {
        setWon(false);
        setGameOver(true);
        
        // Save game completion to history
        const history = loadFromLocalStorage(GAME_HISTORY_KEY) || [];
        const gameResult = {
          date: gameDate,
          won: false,
          movesRemaining: 0,
          resetCount,
          stars: 0,
          timestamp: new Date().toISOString()
        };
        
        // Only add to history if this date doesn't exist yet or if existing result is also a loss
        const existingIndex = history.findIndex(item => item.date === gameDate);
        if (existingIndex >= 0) {
          // Only update if existing result is also a loss (don't overwrite wins)
          if (!history[existingIndex].won) {
            history[existingIndex] = gameResult;
          }
        } else {
          // Add new result
          history.push(gameResult);
        }
        
        saveToLocalStorage(GAME_HISTORY_KEY, history);
      }
    }
  }, [grid, moves, loading, error, gameData.correctGrid, gameDate, resetCount, firstMoveTime]);
  
  // Add auto-scroll effect when the player wins or loses
  useEffect(() => {
    if (gameOver) {
      const scrollTimer = setTimeout(() => {
        if (gameCompletionRef.current) {
          gameCompletionRef.current.scrollIntoView({ 
            behavior: 'smooth', 
            block: 'start' 
          });
        }
      }, 3000); // 3 seconds delay
      
      return () => clearTimeout(scrollTimer);
    }
  }, [gameOver, won]);

  const handleReset = () => {
    setGrid(JSON.parse(JSON.stringify(gameData.initialGrid))); // Deep copy
    setMoves(15);
    setGameOver(false);
    setWon(false);
    setShowWordList(false);
    setResetCount(prevCount => prevCount + 1); // Increment reset counter
    setFirstMoveTime(null);
  };

  const handleFirstMove = () => {
    if (!firstMoveTime) {
      setFirstMoveTime(new Date().toISOString());
      // Save to game state
      const gameState = loadFromLocalStorage(GAME_STATE_KEY(gameDate)) || {};
      saveToLocalStorage(GAME_STATE_KEY(gameDate), {
        ...gameState,
        firstMoveTime: new Date().toISOString()
      });
    }
  };

  if (loading) {
    return <div className="loading">Laddar spelet...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="game-container">
      <div className="today-date">
        {gameData.name || displayDate}
      </div>
      {gameOver && won && <Fireworks />}
      
      <div className="game-instructions">
        <p>⬜ Grå brickor ligger fel</p>
        <p>🟩 Gröna brickor ligger rätt</p>
        <p>🟨 Gula ligger fel men i rätt rad eller kolumn</p>
        <p>Flytta brickorna för att bilda sex svenska ord</p>
      </div>
      
      <GameBoard 
        grid={grid} 
        setGrid={setGrid} 
        correctGrid={gameData.correctGrid} 
        moves={moves} 
        setMoves={setMoves} 
        gameOver={gameOver}
        won={won}
        onFirstMove={handleFirstMove}
      />
      
      <p className="drag-kvar">
        <span className="moves-count">{moves}</span> <span className="drag-text">DRAG KVAR</span>
      </p>
      
      <button onClick={handleReset}>Börja om</button>
      
      <ResetCounter count={resetCount} won={won} />
      
      <div className="game-completion-container" ref={gameCompletionRef}>
        {gameOver && <GameOverMessage 
          won={won} 
          movesLeft={moves} 
          gameDate={gameDate} 
          resetCount={resetCount}
          className="game-over-message" 
        />}
        
        {/* Show word list when the player wins */}
        {showWordList && won && (
          <div className="word-list-section">
            <WordList wordDefinitions={gameData.wordDefinitions} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdfrasGame;
