import React from 'react';
import { motion } from 'framer-motion';

const Fireworks = () => {
  // Create an array of 12 particles for multiple firework bursts
  const particles = Array.from({ length: 12 });

  return (
    <div className="fireworks-container">
      {particles.map((_, index) => (
        <motion.div
          key={index}
          className="firework"
          initial={{ 
            opacity: 0,
            scale: 0,
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight
          }}
          animate={{ 
            opacity: [0, 1, 0],
            scale: [0, 1, 0],
            y: [null, Math.random() * -100]
          }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            delay: index * 0.2,
            ease: "easeOut"
          }}
        />
      ))}
    </div>
  );
};

export default Fireworks;