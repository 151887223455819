import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";

const Tile = ({ letter, rowIndex, colIndex, tileStatus, swapLetters, gameOver, won }) => {
  // Only allow dragging if the tile has a letter, the game is not over, and the tile is not already correct
  const isDraggable = !gameOver && letter !== null && tileStatus !== "correct";
  const tileRef = useRef(null);
  
  // Ensure no transition animations by directly setting CSS
  useEffect(() => {
    if (tileRef.current) {
      // This overrides any animation/transition happening
      tileRef.current.style.transition = "none";
    }
  });
  
  const handleDragEnd = (event, info) => {
    if (!isDraggable) return;
    
    // Prevent the animation when tile is released
    if (tileRef.current) {
      tileRef.current.style.transition = "none !important";
      tileRef.current.style.animation = "none !important";
    }
    
    // Get the center position of the dragged tile
    const rect = event.target.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    
    // Find all elements at this point
    const elementsAtPoint = document.elementsFromPoint(centerX, centerY);
    
    // Find a target tile to swap with (if any)
    const targetTile = elementsAtPoint.find(el => 
      el !== event.target && 
      el.classList.contains('tile') && 
      !el.classList.contains('hidden') && 
      !el.classList.contains('correct')
    );
    
    if (targetTile) {
      const key = targetTile.getAttribute('data-key');
      if (key) {
        const [targetRow, targetCol] = key.split('-').map(Number);
        swapLetters(rowIndex, colIndex, targetRow, targetCol);
      }
    }
  };
  
  const className = `tile ${tileStatus} ${letter === null ? "hidden" : ""} ${gameOver && !won ? "lose-tile" : ""} ${won ? "win-tile" : ""}`;
  
  // Only use motion.div for draggable tiles
  if (isDraggable) {
    return (
      <motion.div
        ref={tileRef}
        className={className}
        drag={true}
        dragMomentum={false}
        dragSnapToOrigin
        onDragEnd={handleDragEnd}
        transition={{ duration: 0 }}
        style={{ transition: "none" }}
        data-key={`${rowIndex}-${colIndex}`}
        whileDrag={{
          scale: 1.2,
          zIndex: 100,
          boxShadow: "0 8px 16px rgba(0,0,0,0.3)"
        }}
      >
        {letter}
      </motion.div>
    );
  } else {
    // Use regular div for non-draggable tiles
    return (
      <div
        ref={tileRef}
        className={className}
        data-key={`${rowIndex}-${colIndex}`}
        style={{ transition: "none" }}
      >
        {letter !== null ? letter : ""}
      </div>
    );
  }
};

export default Tile;
